import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  backendUrl: 'https://api.dev.pocketdapp.com',
  firebase: {
    projectId: '',
    appId: '',
    storageBucket: '',
    apiKey: '',
    authDomain: '',
    messagingSenderId: '',
    measurementId: '',
  },
};
